import React from "react"

import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"

import {
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import Video from "../../Video/Video"
import RichText from "../RichText/RichText"
import { MainBannerProps } from "./MainBanner.types"

import "./MainBanner.css"

export default function MainBanner(props: MainBannerProps) {
  const isMobile = useMobileMediaQuery()
  const background = isMobile ? props.background_mobile : props.background
  const video = background?.mimeType.includes("video") ? background.url : null
  const optimizedVideo = video ? useVideoOptimization(background?.url) : null
  const optimizedImage = !video ? useImageOptimization(background.url) : null
  return (
    <div
      className="landing-main-banner"
      style={
        optimizedImage
          ? {
              backgroundImage: `url(${optimizedImage.optimized})`,
            }
          : {}
      }
    >
      {optimizedVideo && (
        <Video
          className="landing-main-banner__video"
          loop
          muted
          autoPlay
          source={optimizedVideo}
        />
      )}
      <div className="landing-main-banner__info">
        <div className="landing-main-banner__text-container">
          {props.top_info && (
            <RichText
              className="landing-main-banner__rich-text"
              {...props.top_info}
            />
          )}
        </div>
        <div className="landing-main-banner__text-container">
          {props.middle_info && (
            <RichText
              className="landing-main-banner__rich-text"
              {...props.middle_info}
            />
          )}
        </div>
        <div className="landing-main-banner__text-container">
          {props.bottom_info && (
            <RichText
              className="landing-main-banner__rich-text"
              {...props.bottom_info}
            />
          )}
        </div>
      </div>
    </div>
  )
}
